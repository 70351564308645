<template>
  <el-form :model="form" :rules="rules" ref="form" @submit.native.prevent="onSubmit" class="w-100">
    <!-- Project -->
    <el-form-item label="Projekt" prop="project">
      <select v-model="form.project" class="w-100" :disabled="edit" @change="onChangeFormInput">
        <option :value="project.id" v-for="project in projects" :key="project.id">{{ project.name }}</option>
      </select>
    </el-form-item>

    <!-- Tag -->
    <el-form-item label="Aufgabe" prop="tag">
      <select v-model="form.tag" class="w-100" @change="onChangeFormInput">
        <option :value="tag.id" v-for="tag in tags" :key="tag.id">{{ tag.name }}</option>
      </select>
    </el-form-item>

    <!-- Date -->
    <el-form-item label="Datum">
      <el-date-picker
        v-model="form.startDay"
        type="date"
        placeholder="Pick a day"
        :disabled="disableStartDay"
        :default-value="format(new Date(), 'yyyy-MM-dd')"
        @change="onChangeFormInput"
      ></el-date-picker>
      <el-button type="text" class="m-l-1" @click="disableStartDay = !disableStartDay">
        <span v-if="disableStartDay">Bearbeiten</span>
        <span v-else>Sperren</span>
      </el-button>
    </el-form-item>

    <!-- Start/End -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="7">
        <el-form-item label="Start" prop="start">
          <el-input placeholder="08:00" suffix-icon="el-icon-date" v-model="form.start" @change="onChangeFormInput"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="7">
        <el-form-item label="Ende" prop="end">
          <el-input placeholder="09:00" suffix-icon="el-icon-date" v-model="form.end" @change="onChangeFormInput"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="10" class style="margin-top: 40px">
        <el-button type="primary" plain @click="startTimer">Start</el-button>
        <el-button type="primary" plain class="m-l-1" @click="stopTimer">End</el-button>
      </el-col>
    </el-row>

    <!-- Comment -->
    <el-form-item label="Kommentar" prop="description">
      <el-input type="textarea" :rows="4" placeholder="Kommentar" v-model="form.description" @change="onChangeFormInput"></el-input>
    </el-form-item>

    <!-- Submit -->
    <div class="m-t-2 m-b-0 flex">
      <el-button type="info" @click.prevent="clearForm(true)" plain class="w-100">Zurücksetzen</el-button>

      <el-button v-if="edit" type="primary" @click="doChangeTimetracker" class="w-100" :loading="loading">Zeiteintrag ändern</el-button>
      <el-button v-else type="primary" native-type="submit" class="w-100" :loading="loading">Zeit erfassen</el-button>
    </div>
  </el-form>
</template>

<script>
import { addHours, setHours, setMinutes, format } from 'date-fns';

export default {
  name: 'FormTimetracking',

  props: {
    data: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },

  computed: {
    end() {
      const split = this.form.end.split(':');
      const hours = parseInt(split[0]);
      const minutes = split[1];

      let result = setHours(new Date(this.form.startDay), hours);
      result = setMinutes(new Date(result), minutes);
      return format(new Date(result), "yyyy-MM-dd'T'HH:mm:ss");
    },

    start() {
      const split = this.form.start.split(':');
      const hours = parseInt(split[0]);
      const minutes = split[1];

      let result = setHours(new Date(this.form.startDay), hours);
      result = setMinutes(new Date(result), minutes);
      return format(new Date(result), "yyyy-MM-dd'T'HH:mm:ss");
    },

    persistentForm() {
      return this.$store.state.tracking.form;
    },

    projects() {
      return this.$store.state.project.all;
    },

    tags() {
      return this.$store.state.tag.all;
    },

    user() {
      return this.$store.state.user.current;
    }
  },

  data() {
    return {
      billed: false,
      disableStartDay: true,
      edit: false,
      form: {
        project: 0,
        tag: 0,
        user: 0,
        description: '',
        billed: 0,
        start: null,
        end: null,
        startDay: null
      },
      rules: {
        project: [{ required: true, message: 'Bitte Feld ausfüllen', trigger: 'blur' }],
        tag: [{ required: true, message: 'Bitte Feld ausfüllen', trigger: 'blur' }],
        description: [{ required: true, message: 'Bitte Feld ausfüllen', trigger: 'blur' }]
      },
      loading: false
    };
  },

  mounted() {
    this.form.user = this.user.id;
    this.init();

    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'tracking/SET_FORMULAR':
          if (this.form !== state.tracking.form) {
            Object.assign(this.form, state.tracking.form);
          }
      }
    });
  },

  methods: {
    format,

    clearForm(all = false) {
      this.form.description = null;
      this.form.start = format(new Date(), 'HH:mm');
      this.form.end = format(addHours(new Date(), 1), 'HH:mm');

      if (all) {
        this.form.project = 0;
        this.form.tag = 0;
      }

      this.onChangeFormInput();
    },

    doSubmit() {
      this.loading = true;

      const data = Object.assign({}, this.form);
      data.start = this.start;
      data.end = this.end;
      data.user = this.user.id;

      this.$store
        .dispatch('tracking/ADD_TIMETRACKER', data)
        .then(() => {
          this.loading = false;
          this.$notify({
            title: 'Erfolg',
            message: `Zeiteintrag wurde erfolgreich hinzugefügt.`,
            type: 'success',
            duration: 6000
          });
          this.clearForm();
          this.requestTimetracker();
        })
        .catch(err => {
          this.loading = false;
          const errorText = err.response.data.message || err.message;
          this.$notify({
            title: 'Fehler',
            message: `${err.response.status}: ${errorText}`,
            type: 'error',
            duration: 8000
          });
        });
    },

    init() {
      Object.assign(this.form, this.persistentForm);

      if (this.form.startDay === null) {
        this.form.startDay = format(new Date(), 'yyyy-MM-dd');
      }

      if (this.form.start === null) {
        this.form.start = format(new Date(), 'HH:mm');
      }

      if (this.form.end === null) {
        this.form.end = format(addHours(new Date(), 1), 'HH:mm');
      }

      if (this.data.id) {
        this.edit = true;

        Object.assign(this.form, this.data);
      }
    },

    onChangeFormInput() {
      this.$store.commit('tracking/SET_FORMULAR', {
        project: this.form.project,
        tag: this.form.tag,
        startDay: this.form.startDay,
        start: this.form.start,
        end: this.form.end,
        description: this.form.description
      });
    },

    onSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.doSubmit();
        } else {
          //
        }
      });
    },

    startTimer() {
      this.form.start = format(new Date(), 'HH:mm');
      this.onChangeFormInput();
    },

    stopTimer() {
      this.form.end = format(new Date(), 'HH:mm');
      this.onChangeFormInput();
    },

    requestTimetracker() {
      const year = new Date().getFullYear();

      this.$store.dispatch('tracking/GET_TIMETRACKER', {
        filter: {
          start: {
            _gte: `${year}-01-01 00:00:00`
          }
        }
      });
    }
  }
};
</script>
