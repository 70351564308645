<template>
  <el-dropdown trigger="click">
    <el-button type="primary" class="el-dropdown-link">
      Arbeitszeit erfassen
      <i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>

    <el-dropdown-menu slot="dropdown">
      <FormTimetracking class="p-1" />
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import FormTimetracking from '@/components/Form/FormTimetracking.vue';

export default {
  name: 'DropdownCreateTracking',

  components: {
    FormTimetracking
  }
};
</script>

<style scoped lang="scss">
.el-dropdown {
  cursor: pointer;
}
</style>
